@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
    html {
        font-family: Poppins,system-ui, sans-serif;
    }
    ul {
        list-style-type: disc;
        list-style-position: inside;
    }
    ol {
        list-style-type: decimal;
        list-style-position: inside;
    }
    ul ul, ol ul {
        list-style-type: circle;
        list-style-position: inside;
        margin-left: 15px;
    }
    ol ol, ul ol {
        list-style-type: lower-latin;
        list-style-position: inside;
        margin-left: 15px;
    }

    a{
        text-decoration: underline;
        color: #005fb3;
    }
    p{
        size: 14px;
    }


}
